import React from 'react'
import PageHead from 'src/components/head/PageHead'
import { SubscribeSuspended, UnsubscribeForm, NewslatterWrapper } from 'src/components/newsletter'

import { SUBSCRIBE_PREFERENCES_SUSPENDED } from 'src/constants'

// helpers.
const page = 'newsletter-unsubscribe'

const UnsubscribeModal = ({ location }) => (
  <NewslatterWrapper>
    {({ animate }) =>
      SUBSCRIBE_PREFERENCES_SUSPENDED ? (
        <SubscribeSuspended animate={animate} />
      ) : (
        <UnsubscribeForm animate={animate} location={location} />
      )
    }
  </NewslatterWrapper>
)

export default UnsubscribeModal

// <head> component:
export function Head() {
  return <PageHead page={page} noindex />
}
